

body {
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-family: "Roboto 700";
  color: #005ca6;
}

h3{
  margin: 0;
  font-family: 'Roboto Slab', serif;
}

.img-header {
    position: absolute;
    top: 0;
    width: 115px;
  }
  
  .footer {
    position: relative;
    color: #ffffff;
    width: 100%;
    padding: 1.5em;
    overflow: hidden;
    background-color: #0a132d;
  }

  .bg-light {
    background-color: #dbe7ed !important;
  }
  .bg-primary {
    background-color: #3badee !important;
  }

  .btn-link {
    text-decoration: none !important;
  }

  /* SPINNER */
  .loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #333333;
    opacity: .7;
  }
  
  .loader::after {
    content: '';
    width: 75px;
    height: 75px;
    border: 15px solid #dddddd;
    border-top-color: #005ca6;
    border-radius: 50%;
    animation: loading 0.75s ease infinite;
  }

  @keyframes loading {
    from {transform :rotate(0turn);}
    to {transform: rotate(1turn);}
  }
  /* FiN SPINNER */


  .titulo-banner{
    background: #0078D4;
    padding: 2.5rem 0;
    color: #ffffff;
  }
  .titulo-banner h3{
    font-size: 2.8rem;
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
  }

  /* BARRA DE PROGRESO  */
  .progress-bar-new {
    display: flex;
    justify-content: space-around;
    position: relative;
  }
  .contenedor-barra{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
  }

  
  .step {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
  .step.active {
    background-color: #3badee;
    color: white;
  }

  @media (max-width: 576px){
    .contenedor-barra.mobile{
      display: none;
    }
  }
  
  /* FIN BARRA DE PROGRESO */
  
  .text-primary {
      color: #3badee!important;
  }
  .paso{
    color: lightgray;
    margin-top: 10px;
  }
  
  .paso.activo {
    color: #3badee;
    margin-top: 10px;
  }
  
  .prueba {
    color: #3badee;
    text-align: end;
    border-right: 5px solid #198754;
    border-left: left 5px solid #198754;
  }
  
  .msg-asesor{
    background-color: #004F80;
    border-radius: 5px;
    color: #ffffff;
    letter-spacing: 1px;
    margin-bottom: 15px;
    padding: 20px 10px;
  }

  .rrss p{
    font-family: 'Roboto Slab', serif;
  }

  .rrss i {
    color: #0a132d;
  }
  .footer{
    font-family: 'Roboto Slab', serif;
  }